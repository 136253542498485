import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import './style.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    maxWidth: '90%'
  }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function FaceMatchDialog({faceMatchDialogOpen,faceMatchDialogClose, detectFace}) {
  const userReducer = useSelector(state => state.userReducer);
  const {userFace, userPan, systemAadhaar,faceScore, panfaceScore} = userReducer;


  return (
    <div>
      <BootstrapDialog
        onClose={faceMatchDialogClose}
        aria-labelledby="customized-dialog-title"
        open={faceMatchDialogOpen}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={faceMatchDialogClose}>
          Face Match
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div className='matchWrapper'>
          <div className="detailsSubhead">Live Screenshot: User face</div>
            <Button style={{fontWeight: '700', fontSize: '18px'}} onClick={() => detectFace('one')} color={!faceScore ? 'primary' : 'default'} variant='contained'>{!faceScore ? 'Run Face Match' : faceScore}</Button>
            <div className="detailsSubhead">Aadhar Photo</div>
          </div>
          <div className='matchImgWrapper'>
            <img width={300} src={userFace} />
            <img width={300} src={systemAadhaar} />
          </div>
          <div className='matchWrapper'>
          <div className="detailsSubhead">Live Screenshot: User face</div>
            <Button style={{fontWeight: '700', fontSize: '18px'}} onClick={() => detectFace('two')} color={!panfaceScore ? 'primary' : 'default'} variant='contained'>{!panfaceScore ? 'Run Face Match' : panfaceScore}</Button>
            <div className="detailsSubhead">Aadhar Photo</div>
          </div>
          <div className='matchImgWrapper'>
            <img width={300} src={userFace} />
            <img width={300} src={userPan} />
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

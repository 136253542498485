

const initialState = {  
    userFace: null,
    userPan: null,
    userSign: null,
    faceScore: null,
    systemAadhaar: null,
    panfaceScore: null,
  }
  
  function userReducer(state = initialState, action) {
    switch (action.type) {
      case "@@redux/INIT":
        return state;
      case "USER_FACE_SCREENSHOT":
          return {
              ...state,
              userFace: action.payload
          }
      case "USER_PANCARD_SCREENSHOT":
          return {
              ...state,
              userPan: action.payload
          }
      case "USER_SIGN_SCREENSHOT":
          return {
              ...state,
              userSign: action.payload
          }
      case "FACE_MATCH_SCORE":
          return {
              ...state,
              faceScore: action.payload
          }
      case "PANFACE_MATCH_SCORE":
          return {
              ...state,
              panfaceScore: action.payload
          }
      case "SYSTEM_AADHAAR_CARD":
          return {
              ...state,
              systemAadhaar: action.payload
          }
      case "USER_UID_PHOTO":
          return {
              ...state,
              systemAadhaar: action.payload
          }
      case "MEETING_LEFT":
          return {
            ...state,
            userFace: null,
            userPan: null,
            userSign: null,
            faceScore: null,
            systemAadhaar: null,
            panfaceScore: null,
          }
      default:
        return state
    }
  }
  export default userReducer;
  
import React from "react";
import Button from '@material-ui/core/Button';
import {DetailsDialog} from "../DetailsDialog";
import FaceMatchDialog from "../FaceMatchDialog";
import {faceMatchService, b64toBlob} from '../../services/faceMatch';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../Loader";
// import Snackbar from '@mui/material/Snackbar';
// import Alert from '@mui/material/Alert';
import './style.css';


const ActionButtons = ({videoTracks}) => {
  const dispatch = useDispatch();
  const userReducer = useSelector(state => state.userReducer);
  const {userFace, userPan, systemAadhaar} = userReducer;

  const [open, setOpen] = React.useState(false);
  const [openFM, setOpenFM] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  // const [notify, setNotify] = React.useState(false);
  // const [notifyMsg, setNotifyMsg] = React.useState(null);

  const openDetailsDialog = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const OpenFaceDialog = () => {
    setOpenFM(true);
  }
  const faceMatchDialogClose = () => {
    setOpenFM(false);
  }


  const takeScreenshot = (name) => {
      let canvas = document.createElement('canvas');
      // canvas.width = 480;
      // canvas.height = 640;
      let ctx = canvas.getContext('2d');
      let video = document.querySelector(`#video_${videoTracks.id} video`);
      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;
      console.log(video);
      //draw image to canvas. scale to target dimensions
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      //convert to desired file format
      
      var dataURI = canvas.toDataURL('image/jpeg');
      console.log(dataURI);
      switch (name) {
        case 'face':
          dispatch({type: "USER_FACE_SCREENSHOT", payload: dataURI});
          break;
        case 'panCard': 
          dispatch({type: "USER_PANCARD_SCREENSHOT", payload: dataURI});
        break;
        case 'sign': 
          dispatch({type: "USER_SIGN_SCREENSHOT", payload: dataURI});
        break;
        default:
          break;
      }
      
  }

    async function detectFace(name) {
      let target = await b64toBlob(userFace);
      let srcImg;
      setLoader(true);
      if(name === 'one') {
        srcImg = await b64toBlob(systemAadhaar);
      } else {
        srcImg = await b64toBlob(userPan);
      }
      const formData = new FormData();
      formData.append('client_key', 'dF00TtLlPckNT9FP')
      formData.append('src', srcImg);
      formData.append('target',target)
      faceMatchService(formData).then(res => {
        setLoader(false);
        if(name === 'one') {
          let new_score;
          if(res.matching_score > 54) {
            new_score = Math.pow((res.matching_score/100.0),0.2)*100;
            dispatch({type: "FACE_MATCH_SCORE", payload: new_score});
          } 
          if(new_score > 100) {
            new_score = 100;
            dispatch({type: "FACE_MATCH_SCORE", payload: new_score});
          } 
          else if(res.matching_score <= 54) {
            let score = -5;
            dispatch({type: "FACE_MATCH_SCORE", payload: score});
        }
      } else {
        let new_score;
        if(res.matching_score > 54) {
          new_score = Math.pow((res.matching_score/100.0),0.2)*100;
          dispatch({type: "PANFACE_MATCH_SCORE", payload: new_score});
        } 
        if(new_score > 100) {
          new_score = 100;
          dispatch({type: "PANFACE_MATCH_SCORE", payload: new_score});
        } 
        else if(res.matching_score <= 54) {
          let score = -5;
          dispatch({type: "PANFACE_MATCH_SCORE", payload: score});
        }
      }
      });
    }

    // const uploadFileOnChange = (event, name) => {
    //   let file = event.target.files[0];
    //   var imageReader = new FileReader();
    //   imageReader.onload = function () {
    //     let arrayBuffer = imageReader.result;
    //     if(name === 'face') {
    //       dispatch({type: "USER_FACE_SCREENSHOT", payload: arrayBuffer});
    //     } else if(name === 'sign') {
    //       dispatch({type: "USER_SIGN_SCREENSHOT", payload: arrayBuffer});
    //     } else if(name === 'UIDPhoto') {
    //        dispatch({type: "USER_UID_PHOTO", payload: arrayBuffer});
    //     }
    //     else {
    //       dispatch({type: "USER_PANCARD_SCREENSHOT", payload: arrayBuffer});
    //     }
    //   };
    //   imageReader.readAsDataURL(file);
    // }
  
    return (
      <>
        <div className="buttonWrapper">
          <Button variant="contained" className="allBtns" color="primary" onClick={openDetailsDialog}>User Details</Button>
          {/* <label> User Photo
            <input type="file" id="uploadFile" accept="image/*" style={{ display: 'none' }} onChange={(e, name) => uploadFileOnChange(e, 'face')} />
          </label>
           <label> Aadhar Photo
            <input type="file" id="uploadFile" accept="image/*" style={{ display: 'none' }} onChange={(e, name) => uploadFileOnChange(e, 'UIDPhoto')} />
          </label>
          <label> PAN/Aadhaar Card
            <input type="file" id="uploadFile" accept="image/*" style={{ display: 'none' }} onChange={(e, name) => uploadFileOnChange(e, 'panCard')} />
          </label>
          <label> signature
            <input type="file" id="uploadFile" accept="image/*" style={{ display: 'none' }} onChange={(e, name) => uploadFileOnChange(e, 'sign')} />
          </label> */}
          <Button variant="contained" className="allBtns" color="primary" onClick={() => takeScreenshot('face')}>User Photo</Button>
          <Button variant="contained" className="allBtns" color="primary" onClick={() => takeScreenshot('panCard')}>PAN/Aadhaar Card</Button>
          <Button variant="contained" className="allBtns" color="primary" onClick={() => takeScreenshot('sign')}>Signature</Button>
          <Button variant="contained" className="allBtns" color="primary" onClick={() => OpenFaceDialog()} >Face Match</Button>
        </div>
        <DetailsDialog handleOpen={open} handleClose={handleClose}  />
        <FaceMatchDialog faceMatchDialogOpen={openFM} faceMatchDialogClose={faceMatchDialogClose} detectFace={(name) => detectFace(name)} />
       {loader ? <Loader /> : null}
       {/* <Snackbar open={notify} autoHideDuration={9000} onClose={notifyClose}>
        <Alert style={{fontSize: '18px'}} onClose={notifyClose} variant="filled" severity="warning" sx={{ width: '100%' }}>
         Matching score: {notifyMsg}
        </Alert>
      </Snackbar> */}
      </>
    );
  };

  export default ActionButtons;